<template>
  <div>
    <components-list></components-list>
    <div class="small_description">
      <p>Маленькое описание снизу</p>
    </div>
  </div>
</template>

<script>
import ComponentsList from "./parts/ComponentsList.vue";

export default {
  components: {
    ComponentsList,
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang = "scss">
.small_description {
  background-color: #19516D;
  border-radius: 0 0 22px 22px;
  padding: 13px 26px;
  border-top: 1px solid #4183A4;
  p {
    margin: 0;
  }
}
</style>